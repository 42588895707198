// components/AboutPage.js

import React from 'react';

const AboutPage = () => {
  return (
    <div className='bg-primary-subtle py-5'>
      <h1>Join-Tech Computer Institute</h1>
      <p>
      It's a great place to learn about Basic to advance computer courses. Enhance your computer skills, learn coding from experienced faculty.
        At Join-Tech Computer Institute, we are committed to providing high-quality, accessible education to individuals worldwide. 
        Our mission is to facilitate learning and growth by offering a diverse range of courses taught by industry experts and thought leaders.
      </p>
      <p>
        Our platform is designed to empower learners of all backgrounds and skill levels to pursue their passions, 
        advance their careers, and achieve their goals through lifelong learning.
      </p>
      <p>
        Whether you're a professional looking to upskill, a student exploring new interests, or someone simply eager to learn something new,
        we're here to support you every step of the way.
      </p>
    </div>
  );
}

export default AboutPage;
