import './Webdev.css'; 
const Webdev = ()=>{
    return(
        <section className="advanced-courses bg-black py-5">
        <div className="container">
          <h2 className="text-center text-light mb-4">FullStack Web Development</h2>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="card">
                <div className="card-body bg-black bg-gradient text-white">
                  <h3 className="card-title text-primary">Frontend</h3>
                  <h4 className="text-secondary">Topics</h4>
                  <ul className="list-unstyled">
                    <li>HTML5, CSS3, Bootstrap</li>
                    <li>Build the frontend for a product using HTML, CSS, Bootstrap from scratch</li>
                    <li>Interview Preparation</li>
                  </ul>
                  <h4 className="text-secondary">Hands on Experience</h4>
                  <ul className="list-unstyled">
                    <li>Build static web pages using HTML</li>
                    <li>Create responsive website using CSS and Bootstrap</li>
                    <li>Advanced Styling Techniques</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="card">
                <div className="card-body bg-black bg-gradient text-white">
                  <h3 className="card-title text-primary">Build Frontend Using React</h3>
                  <h4 className="text-secondary">Topics</h4>
                  <ul className="list-unstyled">
                    <li>React.js</li>
                    <li>Learn and implement React from scratch to simplify your frontend code and improve the page performances</li>
                    <li>React interview preparation</li>
                  </ul>
                  <h4 className="text-secondary">Hands on Experience</h4>
                  <ul className="list-unstyled">
                    <li>Create Dynamic Websites using React</li>
                    <li>Connecting Frontend with Backend</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 mb-4">
              <div className="card ">
                <div className="card-body bg-black bg-gradient text-white">
                  <h3 className="card-title text-primary">Backend - NodeJs</h3>
                  <h4 className="text-secondary">Topics</h4>
                  <ul className="list-unstyled">
                    <li>Express.js, Node.js, MongoDB</li>
                    <li>Work with MERN Stack backend components</li>
                    <li>Interview Preparation</li>
                  </ul>
                  <h4 className="text-secondary">Hands on Experience</h4>
                  <ul className="list-unstyled">
                    <li>Work with Node.js Libraries</li>
                    <li>Create APIs with ExpressJS and MySql Database</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-4">
              <div className="card">
                <div className="card-body bg-black bg-gradient text-white">
                  <h3 className="card-title text-primary">Backend - Python</h3>
                  <h4 className="text-secondary">Topics</h4>
                  <ul className="list-unstyled">
                    <li>Flask, Django</li>
                    <li>Working with Python-based backend for web development</li>
                    <li>RESTful API Development</li>
                  </ul>
                  <h4 className="text-secondary">Hands on Experience</h4>
                  <ul className="list-unstyled">
                    <li>Building web applications with Flask and/or Django</li>
                    <li>Integrating frontend with Python backend</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <p className="text-center text-light mt-4">
            Note: We will also cover MVC Architecture. Live projects during classes. Live Deployments. Version Control: Github
          </p>
        </div>
      </section>
    )
}
export default Webdev;