import { Link } from 'react-router-dom';

const FeatureCourse = ()=>{
    return(
        <section className="featured-courses bg-success-subtle py-5">
        <div className="container">
          <h2 className="text-center mb-4">Featured Courses</h2>
          <div className="row">
            {/* Example course cards */}
            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                  <h1 className="card-title">PGDCA</h1>
                  <p className="card-text"> <b>Short description of the course.</b>  </p>
                  <h4> MS-Office: </h4>
                  <p> WORD, EXCEL, POWERPOINT, ACCESS   </p>
                  <h5> DTP: </h5>
                  <p> PAGEMAKER, PHOTOSHOP, CORELDRAW </p>
                  <p> MySQL, DOS, C PROGRAMMING, TALLY BASICS, USES OF INTERNET</p>
                  {/* <Link to="/course/1" className="btn btn-primary" >Learn More</Link> */}
                </div>
              </div>
            </div>


            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                  <h1 className="card-title">HARDWARE & NETWORKING </h1>
                  <p className="card-text"> <b>Short description of the course.</b>  </p>
                  <h4> ASSEMBLING AND DISSEMBLING </h4>
                  <p> HARDWARE INSTALLATION  </p>
                  <h5> SOFTWARE INSTALLATION </h5>
                  <p> IP ADDRESS CONCEPT </p>
                  <p> DUAL BOOTING ,SHARING ETC </p>
                  {/* <Link to="/course/1" className="btn btn-primary" >Learn More</Link> */}
                </div>
              </div>
            </div>

            <div className="col-md-4 mb-4">
              <div className="card">
                <div className="card-body">
                  <h1 className="card-title">ADCA</h1>
                  <p className="card-text"> <b>Short description of the course.</b>  </p>
                  <h4> MS-Office: </h4>
                  <p> WORD, EXCEL, POWERPOINT, ACCESS   </p>
                  <h5> DTP: </h5>
                  <p> PAGEMAKER, PHOTOSHOP, CORELDRAW </p>
                  <p> MySQL, DOS, C PROGRAMMING, TALLY BASICS </p>
                  {/* <Link to="/course/1" className="btn btn-primary" >Learn More</Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
}
export default FeatureCourse;