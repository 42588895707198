import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';

const ExternalPage = () => {
  const [loading, setLoading] = useState(true);

  // This function is triggered when the iframe finishes loading
  const handleIframeLoad = () => {
    setLoading(false);
  };

  return (
    <div style={{ width: '100%', height: 'auto' }}>
      {/* Display the loading message while the iframe is loading */}
      {loading && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <p>Please wait, loading...</p>
        </div>
      )}

      {/* Lazy-loaded iframe */}
      <LazyLoad height={500}>
        <iframe
          src="https://jointechcomputerinstitite.blogspot.com/p/blog-page.html"
          title="External Page"
          style={{
            width: '100%',
            height: '90vh',
            border: 'none',
            display: loading ? 'none' : 'block', // Hide the iframe until it loads
          }}
          onLoad={handleIframeLoad} // Trigger when the iframe has loaded
        />
      </LazyLoad>
    </div>
  );
};

export default ExternalPage;