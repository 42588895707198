import s1 from '../pics/s1.jpeg';
import s2 from '../pics/s2.jpeg';
import s3 from '../pics/s3.jpeg';
import s4 from '../pics/s4.jpeg';


const Passoutstudents = ()=>{
    const courses = [
        {
          id: 1,
          title: "Professional Development",
          description: "Enhance your skills and advance your career with our professional development",
          image: s1,
        },
        {
          id: 2,
          title: "Technology and IT",
          description: "Stay updated with the latest technologies and trends in the IT industry.",
          image: s2,
        },
        {
          id: 3,
          title: "Technology and IT",
          description: "Stay updated with the latest technologies and trends in the IT industry.",
          image: s3,
        },    {
          id: 1,
          title: "Professional Development",
          description: "Enhance your skills and advance your career with our professional development",
          image: s4,
        },
        // Add more courses as needed
      ];
    
      return (
        <div >
          <h1>Passed out students</h1>
          <div className="row">
            {courses.map(course => (
              <div key={course.id} className="col-md-3 mb-4" >
                <div className="card">
                  <img src={course.image} className="card-img-top"  style={{ height: '400px' }} alt={course.title} />
                </div>
              </div>
            ))}
          </div>
        </div>
      );
}
export default Passoutstudents;