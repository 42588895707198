import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import p2 from '../pics/p2.jpeg';
import p3 from '../pics/p3.jpeg';
import p4 from '../pics/p4.jpeg';
import p5 from '../pics/p5.jpeg';
import mam from '../pics/mam.png';

const Carousel = () => {
    return (
        <div>
            <div id="carouselExampleControlsNoTouching" 
                 className="carousel slide" 
                 data-bs-ride="carousel" 
                 data-bs-interval="2000" // Auto-slide every 3 seconds
                 style={{width: "100%", height: "300px"}}>
                
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src={mam} style={{width: "100%", height: "300px"}} className="d-block w-100" alt="Slide 1" />
                    </div>
                    <div className="carousel-item">
                        <img src={p2} style={{width: "100%", height: "300px"}} className="d-block w-100" alt="Slide 2" />
                    </div>
                    <div className="carousel-item">
                        <img src={p3} style={{width: "100%", height: "300px"}} className="d-block w-100" alt="Slide 3" />
                    </div>
                    <div className="carousel-item">
                        <img src={p4} style={{width: "100%", height: "300px"}} className="d-block w-100" alt="Slide 4" />
                    </div>
                    <div className="carousel-item">
                        <img src={p5} style={{width: "100%", height: "300px"}} className="d-block w-100" alt="Slide 5" />
                    </div>
                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    );
}

export default Carousel;