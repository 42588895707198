import React from 'react';
import Carosel from './carosel';
import banner from '../pics/banner.jpg';
import Webdev from './WebDev';
import FeatureCourse from './FeatureCourse';
import Passoutstudents from './PassoutStudent';
const HomePage = () => {

  return (

    <div>
      {/* <div className='bgImg'>
        <section className="hero bg-dark text-light py-5" style={{
          position: 'relative',
          backgroundImage: `url(${banner})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }}>
        </section>
      </div> */}

      <div className='bgImg'>
        <section className="hero bg-dark text-light py-2" style={{
          position: 'relative',
        }}>
          <img src={banner} alt="Banner" style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }} />
          {/* Content Goes Here */}
        </section>
      </div>

      <section className="hero bg-dark bg-dark bg-gradient text-light mb-4 py-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-7">
              <Carosel />
            </div>
            <div className="col-lg-5 d-none d-lg-flex flex-column justify-content-center align-items-center"> {/* Hide on small screens */}
              <h1 className="text-center mb-4">Start your career in Join-Tech Computer Institute</h1>
              <p className="text-center">It's a great place to learn about Basic to advance computer courses. Enhance your computer skills, learn coding from experienced faculty. </p>
            </div>
          </div>
          {/* For small screen devices, show the text content below the carousel */}
          <div className="row d-lg-none"> {/* Show on small screens only */}
            <div className="col-12">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <h1 className="text-center mb-4">Start your career in Join-Tech Computer Institute</h1>
                <p className="text-center">It's a great place to learn about Basic to advance computer courses. Enhance your computer skills, learn coding from experienced faculty. </p>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* Featured Courses Section */}
      <FeatureCourse />

      {/* advance web dev */}

      <Webdev />


      {/* Testimonials Section */}

      {/* <Testimonials /> */}

      {/* Call to Action Section */}
      <section className='bg-primary-subtle py-5'>
        <div className='row'>
          <div className='col-md-12'>
            <Passoutstudents/>
          </div>
        </div>

      </section>

    </div>
  );
}

export default HomePage;
