import React from 'react';
import { HashRouter, Route, Link, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import AboutPage from './components/aboutpage';
// import CoursesPage from './components/courses';
import CourseDetailPage from './components/Details';
import FAQPage from './components/faqpage';
import ContactPage from './components/Footer';
import Register from './components/Register';
import ExternalPage from './components/blogger';
function App() {
  return (
    <HashRouter>
      <div>
        <nav className="navbar navbar-expand-sm navbar-dark bg-primary p-3 sticky-top">
          <div className="container">
            <Link to='/' className='navbar-brand'>Join-Tech Institute</Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link to='/' className='nav-link'>Home</Link>
                </li>
                <li className="nav-item">
                  <Link to='/Updates' className='nav-link'>Updates</Link>
                </li>
                <li className="nav-item">
                  <Link to='/about' className='nav-link'>About</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Routes>
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/about" element={<AboutPage />} />
          <Route exact path="/Updates" element={<ExternalPage />} />
          <Route exact path="/course/:id" element={<CourseDetailPage />} />
          <Route exact path="/faq" element={<FAQPage />} />
          <Route exact path="/Register" element={<Register />} />
          <Route exact path="/contact" element={<ContactPage />} />
        </Routes>
        <footer className="bg-info text-white p-5 mt-5">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <h1 className="text-white">About Us</h1>
                <p>
                  It's a great place to learn about Basic to advance computer courses. Enhance your computer skills, learn coding from experienced faculty.
                </p>
              </div>
              <div className="col-lg-4">
                <h4> Contact to support </h4>
                <p>Mobile no. 6000975234</p>
                <p> E-mail: jointechinstitute@gmail.com </p>
              </div>
              <div className="col-lg-4">
                <h4> Follow us in social media </h4>
                <p>  <i className="fab fa-facebook fa-lg"></i> <a href='https://m.facebook.com/profile.php?id=100088492391293&name=xhp_nt__fb__action__open_user'>www.facebook.com</a>  </p>
                <p>  <i className="fab fa-instagram fa-lg"></i> <a href='https://www.instagram.com/jointechcomputer?igsh=b2M5am45cXRudjA4'> www.instagram.com </a>  </p>

              </div>
            </div>

          </div>

        </footer>
      </div>
    </HashRouter>
  );
}

export default App;
